<template>
  <div>
    <h4 class="ml-2">{{ name }}</h4>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              label="Hotel name"
              :rules="[getRules.required]"
              :error-messages="getHotelErrors.name"
              v-model="form.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="px-3 py-2 default" outlined>What name is the hotel known by?</v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <organization-selector
              @org-selected="orgSelected"
              :isFlat="true"
              :organization_id="form.organization_id"
              :isDisabled="getVendor.status === 'active'"
              label="Select Organization"
              :isRequird="true"
            ></organization-selector>
            <!-- <v-text-field
              label="Company Name"
              :rules="[getRules.required]"
              :error-messages="getHotelErrors.company_name"
              v-model="form.company_name"
              :disabled="getVendor.status === 'active'"
            ></v-text-field> -->
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="px-3 py-2 default" outlined>What name is the hotel's company known by?</v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-card outlined class="px-2">
              <v-checkbox v-model="form.is_hotel_chain" label="Is Hotel Chain"></v-checkbox>
              <v-text-field v-if="form.is_hotel_chain" v-model="form.hotel_chain_name" label="Name"></v-text-field>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="px-3 py-2 default" outlined>What name is the hotel's company known by?</v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <label class="text-color-grey"><small>Classification</small></label>
            <v-rating
              v-model="form.star_rating"
              class="custom-rating"
              background-color="orange lighten-3"
              length="7"
              color="orange"
              medium
            ></v-rating>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="px-3 py-2 default" outlined>What is the classification of this hotel?</v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <label><small>Type of Hotel</small></label>
            <v-item-group multiple v-model="selectedHotelTypes">
              <v-container>
                <v-row>
                  <v-col v-for="(hotelType, index) in form.hotel_types" :key="index" class="p-0 m-0 custom-item-column">
                    <v-item v-slot="{ active, toggle }" :value="hotelType">
                      <v-card
                        :color="active || hotelType.selected ? 'primary' : 'default'"
                        class="d-flex align-center"
                        dark
                        height="75"
                        width="75"
                        @click="toggle"
                        outlined
                      >
                        <v-scroll-y-transition>
                          <div
                            class="text-center flex-grow-1"
                            :class="{
                              'black--text': active === false && hotelType.selected === false
                            }"
                          >
                            <v-icon
                              :color="active === false && hotelType.selected === false ? 'primary' : ''"
                              :class="[hotelType.icon]"
                            ></v-icon>
                            <div>
                              <small>{{ hotelType.name }}</small>
                            </div>
                          </div>
                        </v-scroll-y-transition>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 my-16 default" outlined>What is the type of hotel?</v-card>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="12" md="8">
            <v-card outlined>
              <v-col cols="12" md="8" class="py-0">
                <v-checkbox
                  v-model="isOpeningSeason"
                  @click="checkOpeningSeason"
                  label="Has Opening Season"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="12" class="py-0" v-if="isOpeningSeason">
                <v-row class="my-0" v-for="(openingSeason, index) in form.seasonal_timings" :key="'seasons_' + index">
                  <v-col cols="12" md="6" class="py-0">
                    <v-menu
                      v-model="form.seasonal_timings[index].start_date_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.seasonal_timings[index].start_date"
                          label="From Date"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template slot="append">
                            <v-btn icon @click="form.seasonal_timings[index].start_date_menu = true">
                              <v-icon>mdi-calendar</v-icon>
                            </v-btn>
                          </template></v-text-field
                        >
                      </template>
                      <v-date-picker
                        :min="currentDate"
                        v-model="form.seasonal_timings[index].start_date"
                        @input="form.seasonal_timings[index].start_date_menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0 d-flex">
                    <v-menu
                      v-model="form.seasonal_timings[index].end_date_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.seasonal_timings[index].end_date"
                          label="To Date"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template slot="append">
                            <v-btn icon @click="form.seasonal_timings[index].end_date_menu = true">
                              <v-icon>mdi-calendar</v-icon>
                            </v-btn>
                          </template></v-text-field
                        >
                      </template>
                      <v-date-picker
                        :min="form.seasonal_timings[index].start_date"
                        v-model="form.seasonal_timings[index].end_date"
                        @input="form.seasonal_timings[index].end_date_menu = false"
                      ></v-date-picker>
                    </v-menu>
                    <span
                      v-if="form.seasonal_timings[index].removable"
                      class="mt-5 ml-2 mr-2 pointer"
                      @click="removeOpeningSeason(index)"
                      ><i class="mdi mdi-close"></i
                    ></span>
                  </v-col>
                </v-row>
                <v-row class="my-0">
                  <v-col cols="12" md="8" class="py-0 pb-3">
                    <div class="pointer text--secondary" @click="addOpeningSeason">
                      <i class="mdi mdi-plus"></i> Add Another Opening Season
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-combobox
              append-icon="mdi-map-marker"
              :clearable="clearable"
              :items="addresses"
              label="Hotel Address"
              @keydown="addressTyped"
              @change="venueAddress"
              v-model="selectedVenue"
              item-text="display_name"
              :item-value="item => item"
              :loading="searchLoading"
              hide-no-data
              no-data-text
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined>Click on the map to select the hotel</v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <Map v-if="form.map_location && form.map_location.length > 0" :form="form"></Map>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field label="City" clearable v-model="form.city" :rules="[getRules.required]"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              :items="countries"
              label="Country"
              clearable
              v-model="form.country"
              :rules="[getRules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4"></v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn color="primary" @click="this.continue" class="float-right mt-5 mr-3">Continue</v-btn>
    <v-btn
      color="secondary"
      class="float-right mt-5 mr-1"
      @click="submit('draft')"
      v-if="(getVendor && getVendor.status !== 'active') || getIsVendorUpdate === false"
      >Save As Draft</v-btn
    >
  </div>
</template>

<script>
const Map = () => import(/* webpackChunkName: "map" */ "@/components/Map");
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "BasicInformation",
  props: ["name", "index", "form"],
  components: { Map },
  data: () => ({
    selectedVenue: null,
    searchResults: [],
    searchLoading: false,
    currentDate: new Date().toISOString().substr(0, 10),
    isHotelChain: false,
    isOpeningSeason: false,
    tempSeasonalTime: [],
    selectedHotelTypes: [],
    germanyLatLng: [51.0899232, 5.968358],
    hotelPtcs: [
      {
        name: "Bus",
        icon: "mdi mdi-bus"
      },
      {
        name: "Train",
        icon: "mdi mdi-train"
      },
      {
        name: "Subway",
        icon: "mdi mdi-subway"
      },
      {
        name: "Ferry",
        icon: "mdi mdi-ferry"
      },
      {
        name: "Water Taxi",
        icon: "mdi mdi-ship-wheel"
      }
    ],
    countries: [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Antigua & Deps",
      "Argentina",
      "Armenia",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia",
      "Bosnia Herzegovina",
      "Botswana",
      "Brazil",
      "Brunei",
      "Bulgaria",
      "Burkina",
      "Burundi",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Cape Verde",
      "Central African Rep",
      "Chad",
      "Chile",
      "China",
      "Comoros",
      "Congo",
      "Congo {Democratic Rep}",
      "Costa Rica",
      "Croatia",
      "Cuba",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "East Timor",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Ethiopia",
      "Fiji",
      "Finland",
      "France",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Greece",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Honduras",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland {Republic}",
      "Israel",
      "Italy",
      "Ivory Coast",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Korea North",
      "Korea South",
      "Kosovo",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macedonia",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Micronesia",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "{Burma}",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Qatar",
      "Romania",
      "Russian Federation",
      "Rwanda",
      "St Kitts & Nevis",
      "St Lucia",
      "Saint Vincent & the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome & Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Swaziland",
      "Sweden",
      "Switzerland",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Togo",
      "Tonga",
      "Trinidad & Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Vatican City",
      "Venezuela",
      "Vietnam",
      "Yemen",
      "Zambia",
      "Zimbabwe"
    ],
    clearable: false
  }),
  mounted() {
    this.addressTyped = _.debounce(this.addressTyped, 500);
    this.initAddress();
    if (this.getIsVendorUpdate && this.form.type === "hotel") {
      if (this.form.seasonal_timings && this.form.seasonal_timings.length > 0) {
        this.isOpeningSeason = true;
      }
      if (this.form.hotel_chain_name !== null) {
        this.isHotelChain = true;
      }
      this.form.hotel_types.some((hotel, index) => {
        if (hotel.selected === true) {
          this.selectedHotelTypes.push(hotel);
        }
      });
    }
    // this.orgSelected(this.form.organization_id);
    //this.loadOrganizations();
  },
  computed: {
    addresses() {
      const addresses = [];
      this.searchResults.forEach(result => {
        if (result.display_name) {
          addresses.push({
            display_name: result.display_name,
            latLng: result.lat && result.lon ? [result.lat, result.lon] : [],
            city: result?.address?.city || result?.address?.state,
            country: result?.address?.country
          });
        }
      });
      return addresses;
    },
    ...mapGetters(["getRules", "getHotelErrors", "getIsVendorUpdate", "getVendor"])
  },
  methods: {
    async initAddress() {
      let mapLocation = this.form.map_location || [];
      if (mapLocation.length === 0) {
        let address = this.form.address;
        let addressData = await this.latLngByAddress({ address });
        if (addressData.length > 0) {
          mapLocation = [addressData[0].lat, addressData[0].lon];
          this.form.map_location = mapLocation;
        } else {
          if (!this.form.city && !this.form.country) {
            mapLocation = this.germanyLatLng;
            this.form.map_location = this.germanyLatLng;
          } else if (this.form.city && !this.form.country) {
            let city = this.form.city;
            let addressData = await this.latLngByAddress({ city });
            if (addressData.length > 0) {
              mapLocation = [addressData[0].lat, addressData[0].lon];
              this.form.map_location = mapLocation;
            }
          } else if (!this.form.city && this.form.country) {
            let country = this.form.country;
            let addressData = await this.latLngByAddress({ country });
            if (addressData.length > 0) {
              mapLocation = [addressData[0].lat, addressData[0].lon];
              this.form.map_location = mapLocation;
            }
          }
        }
      }

      this.searchResults.push({
        display_name: this.form.address || "",
        lat: mapLocation ? mapLocation[0] : null,
        lon: mapLocation ? mapLocation[1] : null,
        address: {
          city: this.form.city,
          country: this.form.country
        }
      });
      this.selectedVenue = {
        display_name: this.form.address || "",
        latLng: this.form.map_location,
        city: this.form.city,
        country: this.form.country
      };
    },
    async addressTyped(e) {
      const address = e.target.value;
      this.form.address = address;
      if (address && address.replace(/\s/g, "").length) {
        this.clearable = true;
        this.searchLoading = true;
        let data = await this.latLngByAddress({
          address
          //city: this.form.city,
          //country: this.form.country,
        });
        if (data && data.length && data[0]) {
          this.searchResults = data;
        }
        this.searchLoading = false;
      } else {
        this.clearable = false;
      }
    },
    toggleHotelType(hotelType) {
      if (this.form.hotel_types && this.form.hotel_types.length > 0) {
        this.form.hotel_types.some((hotel, index) => {
          if (hotel.selected === true) {
            this.form.hotel_types[index].selected = false;
          } else {
            this.form.hotel_types.some((hotel, index) => {
              if (hotel.name === hotelType.name) {
                this.form.hotel_types[index].selected = tr;
              }
            });
          }
        });
      }
    },
    addOpeningSeason() {
      this.form.seasonal_timings.push({
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        start_date_menu: false,
        end_date_menu: false,
        removable: true
      });
    },
    removeOpeningSeason(removeOpeningSeasonIndex) {
      let openingSeasons = this.form.seasonal_timings;
      this.form.seasonal_timings = _.filter(openingSeasons, (openingSeason, index) => {
        if (removeOpeningSeasonIndex !== index) {
          return openingSeason;
        }
      });
    },
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
    continue() {
      this.$emit("hotelNextStep", this.index);
    },
    venueAddress(item) {
      if (typeof item === "object") {
        if (item) {
          this.form.address = item.display_name;
          this.form.map_location = item.latLng;
          this.form.city = item.city;
          this.form.country = item.country;
        }
      }
    },
    checkOpeningSeason() {
      if (this.isOpeningSeason) {
        this.form.seasonal_timings = this.tempSeasonalTime;
      }
    },
    orgSelected(orgId) {
      this.form.organization_id = orgId;
    },
    loadComponent(type = "hotel") {
      this.setActiveComponent(type);
      this.$emit("loadComponent", type);
    },
    ...mapMutations(["setActiveComponent", "setVendorOrganization"]),
    ...mapActions(["latLngByAddress", "loadOrganizations"])
  },
  watch: {
    selectedHotelTypes(selectedValues) {
      _.forEach(this.form.hotel_types, (hotelType, index) => {
        this.form.hotel_types[index].selected = false;
      });
      if (selectedValues) {
        _.forEach(selectedValues, selectedValue => {
          let index = _.findIndex(this.form.hotel_types, {
            name: selectedValue.name
          });
          this.form.hotel_types[index].selected = true;
        });
      }
    },
    "form.seasonal_timings": {
      handler(seasons) {
        _.forEach(seasons, (season, index) => {
          if (season.start_date > season.end_date) {
            this.form.seasonal_timings[index].end_date = season.start_date;
          }
        });
      },
      deep: true
    },
    isOpeningSeason: function() {
      if (this.isOpeningSeason === false) {
        this.tempSeasonalTime = this.form.seasonal_timings;
        this.form.seasonal_timings = [];
        // this.form.seasonal_timings = [
        //   {
        //     start_date: new Date().toISOString().substr(0, 10),
        //     end_date: new Date().toISOString().substr(0, 10),
        //     start_date_menu: false,
        //     end_date_menu: false,
        //     removable: true
        //   }
        // ];
      }
    }
  }
};
</script>
<style scoped>
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
.text-color-grey {
  color: #b3b3b3;
}
.pointer {
  cursor: pointer;
}
</style>
<style>
.custom-rating button {
  padding: 0 !important;
}
</style>
