<template>
  <div>
    <h4 class="ml-2">{{ name }}</h4>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              label="Venue name"
              :rules="[getRules.required]"
              :error-messages="getLocationErrors.name"
              v-model="form.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined>What name is the venue known by?</v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <organization-selector
              @org-selected="orgSelected"
              :isFlat="true"
              :organization_id="form.organization_id"
              :isDisabled="getVendor.status === 'active'"
              label="Select Organization"
              :isRequird="true"
            ></organization-selector>
            <!-- <v-text-field
              label="Company Name"
              :rules="[getRules.required]"
              :error-messages="getHotelErrors.company_name"
              v-model="form.company_name"
              :disabled="getVendor.status === 'active'"
            ></v-text-field> -->
          </v-col>
          <v-col cols="12" md="8">
            <label><small>Type of venue</small></label>
            <v-item-group v-model="form.location_types">
              <v-container>
                <v-row>
                  <v-col v-for="(locationType, index) in locationTypes" :key="index" class="p-0 m-0 custom-item-column">
                    <v-item :value="locationType">
                      <v-card
                        :color="isLocationTypeSelected(locationType) === true ? 'primary' : 'default'"
                        class="d-flex align-center"
                        dark
                        height="75"
                        width="75"
                        @click="toggleLocationType(locationType)"
                        outlined
                      >
                        <v-scroll-y-transition>
                          <div
                            class="text-center flex-grow-1"
                            :class="{
                              'black--text': isLocationTypeSelected(locationType) === false
                            }"
                          >
                            <v-icon
                              :color="isLocationTypeSelected(locationType) === false ? 'primary' : ''"
                              :class="[locationType.icon]"
                            ></v-icon>
                            <div>
                              <small>{{ locationType.name }}</small>
                            </div>
                          </div>
                        </v-scroll-y-transition>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          </v-col>

          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 my-16 default" outlined>What is the type of venue?</v-card>
          </v-col>
        </v-row>

        <restaurant-menu-section v-if="isMenuNeeded()" :form="form"> </restaurant-menu-section>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="form.vendor_specifications.total_parking_spaces"
              :rules="[getRules.required, getRules.nonNegativeNumber, getRules.number]"
              min="0"
              label="Amount of Parking Space"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined
              >What is the total number of parking spaces for the venue?</v-card
            >
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col cols="12" md="8">
            <label><small>Public Transport Connections</small></label>
            <v-item-group v-model="form.ptcs" multiple>
              <v-container>
                <v-row>
                  <v-col v-for="(locationPtc, index) in locationPtcs" :key="index" class="p-0 m-0 custom-item-column">
                    <v-item :value="locationPtc">
                      <v-card
                        :color="isPublicTransportConnectionSelected(locationPtc) === true ? 'primary' : 'default'"
                        class="d-flex align-center"
                        dark
                        height="75"
                        width="75"
                        @click="setActive(locationPtc)"
                        outlined
                      >
                        <v-scroll-y-transition>
                          <div
                            class="text-center flex-grow-1"
                            :class="{
                              'black--text': isPublicTransportConnectionSelected(locationPtc) === false
                            }"
                          >
                            <v-icon
                              :color="isPublicTransportConnectionSelected(locationPtc) === false ? 'primary' : ''"
                              :class="[locationPtc.icon]"
                            ></v-icon>
                            <div>
                              <small>{{ locationPtc.name }}</small>
                            </div>
                          </div>
                        </v-scroll-y-transition>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col cols="12">
            <h5>Where is your venue located?</h5>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-combobox
              append-icon="mdi-map-marker"
              :clearable="clearable"
              :items="addresses"
              label="Venue Address"
              @keydown="addressTyped"
              @change="venueAddress"
              v-model="selectedVenue"
              item-text="display_name"
              :item-value="item => item"
              :loading="searchLoading"
              hide-no-data
              no-data-text
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined>Click on the map to select the venue</v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <Map v-if="form.map_location && form.map_location.length > 0" :form="form"></Map>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field label="City" clearable v-model="form.city" :rules="[getRules.required]"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              clearable
              :items="countries"
              label="Country"
              v-model="form.country"
              :rules="[getRules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4"></v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn color="primary" @click="this.continue" class="float-right mt-5 mr-3">Continue</v-btn>
    <v-btn
      color="secondary"
      class="float-right mt-5 mr-1"
      @click="submit('draft')"
      v-if="(getVendor && getVendor.status !== 'active') || getIsVendorUpdate === false"
      >Save As Draft</v-btn
    >
  </div>
</template>

<script>
const Map = () => import(/* webpackChunkName: "map" */ "@/components/Map");
const RestaurantMenuSection = () => import(/* webpackChunkName: "map" */ "@/components/form/RestaurantMenuSection");
import { mapGetters, mapActions } from "vuex";
export default {
  name: "BasicInformation",
  props: ["name", "index", "form"],
  components: { Map, RestaurantMenuSection },
  data: () => ({
    germanyLatLng: [51.0899232, 5.968358],
    selectedVenue: null,
    searchResults: [],
    searchLoading: false,
    clearable: false,
    locationTypes: [
      {
        name: "Restaurant",
        icon: "mdi mdi-food"
      },
      {
        name: "Bar",
        icon: "mdi mdi-glass-cocktail"
      },
      {
        name: "Space",
        icon: "mdi mdi-office-building-outline"
      },
      {
        name: "Resort",
        icon: "mdi mdi-beach"
      },
      {
        name: "Building",
        icon: "mdi mdi-office-building-marker-outline"
      },
      {
        name: "Hotel",
        icon: "mdi mdi-bed"
      },
      {
        name: "Villa",
        icon: "mdi mdi-home-city"
      },
      {
        name: "Beach Club",
        icon: "mdi mdi-beach"
      },
      {
        name: "Garden",
        icon: "mdi mdi-grass"
      },
      {
        name: "Underground",
        icon: "mdi mdi-home-flood"
      },
      {
        name: "Gallery",
        icon: "mdi mdi-picture-in-picture-bottom-right"
      },
      {
        name: "Exhibition Center",
        icon: "mdi mdi-theater"
      },
      {
        name: "Conference Center",
        icon: "mdi mdi-theater"
      },
      {
        name: "Cinema",
        icon: "mdi mdi-theater"
      },
      {
        name: "Theater",
        icon: "mdi mdi-theater"
      },
      {
        name: "Office",
        icon: "mdi mdi-office-building"
      },
      {
        name: "Studio",
        icon: "mdi mdi-home-circle"
      },
      {
        name: "Kitchen",
        icon: "mdi mdi-food-variant"
      },
      {
        name: "Concert Hall",
        icon: "mdi mdi-presentation-play"
      },
      {
        name: "Industrial",
        icon: "mdi mdi-factory"
      },
      {
        name: "Other",
        icon: "mdi mdi-asterisk"
      }
    ],
    locationPtcs: [
      {
        name: "Bus",
        icon: "mdi mdi-bus"
      },
      {
        name: "Train",
        icon: "mdi mdi-train"
      },
      {
        name: "Subway",
        icon: "mdi mdi-subway"
      },
      {
        name: "Ferry",
        icon: "mdi mdi-ferry"
      },
      {
        name: "Water Taxi",
        icon: "mdi mdi-ship-wheel"
      }
    ],
    countries: [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Antigua & Deps",
      "Argentina",
      "Armenia",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia",
      "Bosnia Herzegovina",
      "Botswana",
      "Brazil",
      "Brunei",
      "Bulgaria",
      "Burkina",
      "Burundi",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Cape Verde",
      "Central African Rep",
      "Chad",
      "Chile",
      "China",
      "Comoros",
      "Congo",
      "Congo {Democratic Rep}",
      "Costa Rica",
      "Croatia",
      "Cuba",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "East Timor",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Ethiopia",
      "Fiji",
      "Finland",
      "France",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Greece",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Honduras",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland {Republic}",
      "Israel",
      "Italy",
      "Ivory Coast",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Korea North",
      "Korea South",
      "Kosovo",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macedonia",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Micronesia",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "{Burma}",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Qatar",
      "Romania",
      "Russian Federation",
      "Rwanda",
      "St Kitts & Nevis",
      "St Lucia",
      "Saint Vincent & the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome & Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Swaziland",
      "Sweden",
      "Switzerland",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Togo",
      "Tonga",
      "Trinidad & Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Vatican City",
      "Venezuela",
      "Vietnam",
      "Yemen",
      "Zambia",
      "Zimbabwe"
    ]
  }),
  mounted() {
    this.addressTyped = _.debounce(this.addressTyped, 500);
    this.initAddress();
  },
  computed: {
    addresses() {
      const addresses = [];
      this.searchResults.forEach(result => {
        if (result.display_name) {
          addresses.push({
            display_name: result.display_name,
            latLng: [result.lat, result.lon],
            city: result?.address?.city || result?.address?.state,
            country: result?.address?.country
          });
        }
      });
      return addresses;
    },
    ...mapGetters(["getRules", "getLocationErrors", "getVendor", "getIsVendorUpdate"])
  },
  watch: {
    /*"form.map_location": function (newCords, oldCords) {
      const selectedPlace = this.searchResults.find(
        (result) => result.lat == newCords[0] && result.lon == newCords[1]
      );
      if (selectedPlace) {
        if (typeof selectedPlace.address.city !== "undefined") {
          this.form.city = selectedPlace.address.city;
        } else if (typeof selectedPlace.address.state !== "undefined") {
          this.form.city = selectedPlace.address.state;
        }
        this.form.country = selectedPlace.address.country;
      }
    },*/
  },
  methods: {
    orgSelected(orgId) {
      this.form.organization_id = orgId;
    },
    async initAddress() {
      let mapLocation = this.form.map_location || [];

      if (mapLocation.length === 0) {
        let address = this.form.address;
        let addressData = await this.latLngByAddress({ address });
        if (addressData.length > 0) {
          mapLocation = [addressData[0].lat, addressData[0].lon];
          this.form.map_location = mapLocation;
        } else {
          if (!this.form.city && !this.form.country) {
            mapLocation = this.germanyLatLng;
            this.form.map_location = this.germanyLatLng;
          } else if (this.form.city && !this.form.country) {
            let city = this.form.city;
            let addressData = await this.latLngByAddress({ city });
            if (addressData.length > 0) {
              mapLocation = [addressData[0].lat, addressData[0].lon];
              this.form.map_location = mapLocation;
            }
          } else if (!this.form.city && this.form.country) {
            let country = this.form.country;
            let addressData = await this.latLngByAddress({ country });
            if (addressData.length > 0) {
              mapLocation = [addressData[0].lat, addressData[0].lon];
              this.form.map_location = mapLocation;
            }
          }
        }
      }

      this.searchResults.push({
        display_name: this.form.address || "",
        lat: mapLocation ? mapLocation[0] : null,
        lon: mapLocation ? mapLocation[1] : null,
        address: {
          city: this.form.city,
          country: this.form.country
        }
      });
      this.selectedVenue = {
        display_name: this.form.address || "",
        latLng: this.form.map_location,
        city: this.form.city,
        country: this.form.country
      };
    },
    venueAddress(item) {
      if (typeof item === "object") {
        if (item) {
          this.form.address = item.display_name;
          this.form.map_location = item.latLng;
          this.form.city = item.city;
          this.form.country = item.country;
        }
      }
    },
    async addressTyped(e) {
      const address = e.target.value;
      this.form.address = address;
      if (address && address.replace(/\s/g, "").length) {
        this.clearable = true;
        this.searchLoading = true;
        let data = await this.latLngByAddress({
          address
          //city: this.form.city,
          //country: this.form.country,
        });
        if (data && data.length && data[0]) {
          this.searchResults = data;
        }
        this.searchLoading = false;
      } else {
        this.clearable = false;
      }
    },
    setActive(ptc) {
      if (this.form.ptcs && this.form.ptcs.length > 0) {
        if (this.form.ptcs.some(arrangement => arrangement.name === ptc.name)) {
          this.form.ptcs = this.form.ptcs.filter(item => item.name !== ptc.name);
        } else {
          let publicTransport = [];
          this.form.ptcs.forEach(p => {
            publicTransport.push(p);
          });
          this.form.ptcs = [];
          publicTransport.push(ptc);
          publicTransport.forEach(arrangement => {
            if (arrangement.name.replace(/ /g, "") !== "") {
              this.form.ptcs.push({
                name: arrangement.name,
                icon: arrangement.icon
              });
            }
          });
        }
      } else {
        this.form.ptcs = [];
        this.form.ptcs.push(ptc);
      }
    },
    toggleLocationType(locationType) {
      if (this.form.location_types && this.form.location_types.name) {
        if (this.form.location_types.name === locationType.name) {
          this.form.location_types = null;
        } else {
          this.form.location_types = null;
          this.form.location_types = locationType;
        }
      } else {
        this.form.location_types = null;
        this.form.location_types = locationType;
      }
    },
    isLocationTypeSelected(locationType) {
      let isSelected = false;
      if (this.form.location_types) {
        if (this.form.location_types.name === locationType.name) {
          isSelected = true;
        }
      }
      return isSelected;
    },
    isPublicTransportConnectionSelected(locationPtc) {
      let isSelected = false;
      if (this.form.ptcs) {
        this.form.ptcs.forEach(ptc => {
          if (ptc.name === locationPtc.name) {
            isSelected = true;
          }
        });
      }
      return isSelected;
    },
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
    continue() {
      this.$emit("locationNextStep", this.index);
    },
    isMenuNeeded() {
      return (
        this.form.location_types &&
        (this.form.location_types.name == "Restaurant" || this.form.location_types.name == "Bar")
      );
    },
    ...mapActions(["latLngByAddress"])
  }
};
</script>
<style scoped lang="scss">
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
</style>
